@import "./assets/styles/index.styl"
@import "../../../libs/shared-assets/styles/colors.styl";

/* You can add global styles to this file, and also import other style files */
:root
  /* Colors definition */
  --green-color: #00B283
  --green-secondary-color: #CCF0E6
  --green-dark-color: #00A38D
  --green-light-color: #14CC9C
  //--green-l1-color: lighten(#00B283, 10)
  //--green-l2-color: lighten(#00B283, 20)

  --white-color: #FFFFFF
  --black-color: #121010
  --gray-color: #F7F8FC
  --gray1-color: #707070
  --gray2-color: #DFE0EB
  --gray3-color #E2E2E6
  --gray4-color #D0CFCF
  --red1-color: #F45B69
  --red2-color: #FDDEE1
  --purple-color: #6E44FF
  --warning-color: #FFEDC2;
  --light-blue-color: #CAE6FF

  /* Application colors */
  --brand-color: var(--color-primary)
  --brand-hover-color: var(--color-primary-dark)
  --brand-focus-color: var(--color-primary-dark)
  --brand-light-color: lighten(#9FB8EB, 95%)
  --brand-hover-light-color: var(--color-primary-dark);
  //--brand-dark-color var(--blue-dark-color)
  //--brand-l1-color: var(--blue-l1-color)
  //--brand-l2-color: var(--blue-l2-color)
  //--brand-d1-color: var(--blue-d1-color)
  //--brand-d2-color: var(--blue-d2-color)

  --border-color: var(--color-neutral-300)
  --blue-color-link: var(--color-primary-dark)

  --bg-primary-color: var(--gray-color)
  --bg-secondary-color: var(--white-color)
  --bg-fail-color: var(--color-danger)
  --bg-info-color: var(--color-information)
  --bg-warning-color: var(--color-warning)
  --bg-success-color: var(--color-success)


  --fg-primary-color: var(--black-color)
  --fg-secondary-color: var(--gray1-color)
  --fg-disabled-color: var(--gray2-color)
  --fg-link-color: var(--color-primary-dark)

  --primary-button-color: var(--white-color)

  /* Font */
  --font-family: Inter, sans-serif
  --fw-bold: 700
  --fw-semi-bold: 600
  --fw-medium: 500
  --fw-regular: 400

  --fs-h1: 28px
  --fs-h2: 24px
  --fs-h3: 20px
  --fs-h4: 16px
  --fs-gh: 16px
  --fs-xs: 12px
  --fs-label 13px

  --lh-h1: 36px
  --lh-h2: 32px
  --lh-h3: 28px
  --lh-h4: 24px
  --lh-gh: 28px
  --lh-xs: 16px
  --lh-label 20px

  /* Layout */
  --header-height 6rem

  @media screen and (max-width 375px)
    --header-height 5.2rem

[app-root]
  height 100%
  min-width auto !important

h2
  font-weight var(--fw-semi-bold)
  font-size var(--fs-h2)
  line-height  var(--lh-h2)

.body_text
  font-weight var(--fw-regular)
  font-size var(--fs-h4)
  line-height  var(--lh-h4)

.small_text
  font-size var(--fs-label)
  line-height var(--lh-label)

/* KKM Button */
button.kkm-button
  &.mat-button
    padding 1.5rem 3.2rem
    height 5.6rem

/* KKM Form Field Component */
mat-form-field.kkm-form-field.mat-form-field
  /* Appearance = fill */
  &.mat-form-field-appearance-fill
    .mat-form-field-flex
      padding 0.7rem 1.6rem 0 1.6rem
      max-height 56px
      font-size var(--fs-h4)
      line-height var(--lh-h4)
      font-family var(--font-family)

/* KKM Dialog Component */
.kkm-dialog_panel
  .mat-dialog-container
    .mat-dialog-title
      font-weight var(--fw-semi-bold)
      font-size var(--fs-h2)
      line-height  var(--lh-h2)

/* search field in registration -> choose address */
mat-form-field.choose_address-search_field.mat-form-field.compact.mat-form-field-appearance-fill .mat-form-field-flex
  height 48px

.google_map
  color #707070
  &-controls_container
    top 10px !important
    right 10px !important
    width 40px
    height calc(3 * 40px + 1px + 16px) // 3 buttons * 40px height + 1px separator + 16px margin
  &-controls_wrapper
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    border-radius: 2px;
  &-control_button
    display block
    border none
    margin 0
    padding 0
    position relative
    cursor pointer
    user-select none
    overflow hidden
    width 4rem
    height 4rem
    &-location
      background no-repeat center url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjY3MTMgMTkuMDAwMUgxMi42MTEzQzEyLjM5MTIgMTguOTg2OCAxMi4xODE3IDE4LjkwMTIgMTIuMDE1MyAxOC43NTY1QzExLjg0ODkgMTguNjExOCAxMS43MzUgMTguNDE2MiAxMS42OTEzIDE4LjIwMDFMMTAuMDAxMyAxMC4wMDAxTDEuODAxMjkgOC4zMTAwNkMxLjU4NDc2IDguMjY3MDYgMS4zODg1NSA4LjE1MzYxIDEuMjQzMjUgNy45ODc0MUMxLjA5Nzk0IDcuODIxMjEgMS4wMTE3MiA3LjYxMTYxIDAuOTk4MDIyIDcuMzkxMjdDMC45ODQzMjIgNy4xNzA5NCAxLjA0MzkyIDYuOTUyMjcgMS4xNjc1MSA2Ljc2OTM1QzEuMjkxMTEgNi41ODY0MyAxLjQ3MTc2IDYuNDQ5NTYgMS42ODEyOSA2LjM4MDA2TDE3LjY4MTMgMS4wNTAwNkMxNy44NjA5IDAuOTc1OTAzIDE4LjA1ODIgMC45NTU2NzQgMTguMjQ5MSAwLjk5MTg0N0MxOC40NCAxLjAyODAyIDE4LjYxNjMgMS4xMTkwMyAxOC43NTYzIDEuMjUzNzRDMTguODk2MyAxLjM4ODQ2IDE4Ljk5NCAxLjU2MTA1IDE5LjAzNzUgMS43NTA0MkMxOS4wODExIDEuOTM5NzkgMTkuMDY4NSAyLjEzNzczIDE5LjAwMTMgMi4zMjAwNkwxMy42NzEzIDE4LjMyMDFDMTMuNjAxNyAxOC41MjcgMTMuNDY2MyAxOC43MDU2IDEzLjI4NTcgMTguODI4M0MxMy4xMDUyIDE4Ljk1MTEgMTIuODg5NCAxOS4wMTE0IDEyLjY3MTMgMTkuMDAwMVpNNS44NzEyOSA3LjEwMDA2TDExLjA2MTMgOC4xNjAwNkMxMS4yNTUzIDguMTk3NDggMTEuNDMzOSA4LjI5MTU0IDExLjU3NDUgOC40MzAzNkMxMS43MTUxIDguNTY5MTggMTEuODExNCA4Ljc0NjU0IDExLjg1MTMgOC45NDAwNkwxMi45MDEzIDE0LjEzMDFMMTYuNDIxMyAzLjU4MDA2TDUuODcxMjkgNy4xMDAwNloiIGZpbGw9IiM3MDcwNzAiLz4KPC9zdmc+Cg=="), #fff
    &-zoom_in
      background no-repeat center url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1IDdIOVYxQzkgMC43MzQ3ODQgOC44OTQ2NCAwLjQ4MDQzIDguNzA3MTEgMC4yOTI4OTNDOC41MTk1NyAwLjEwNTM1NyA4LjI2NTIyIDAgOCAwQzcuNzM0NzggMCA3LjQ4MDQzIDAuMTA1MzU3IDcuMjkyODkgMC4yOTI4OTNDNy4xMDUzNiAwLjQ4MDQzIDcgMC43MzQ3ODQgNyAxVjdIMUMwLjczNDc4NCA3IDAuNDgwNDMgNy4xMDUzNiAwLjI5Mjg5MyA3LjI5Mjg5QzAuMTA1MzU3IDcuNDgwNDMgMCA3LjczNDc4IDAgOEMwIDguMjY1MjIgMC4xMDUzNTcgOC41MTk1NyAwLjI5Mjg5MyA4LjcwNzExQzAuNDgwNDMgOC44OTQ2NCAwLjczNDc4NCA5IDEgOUg3VjE1QzcgMTUuMjY1MiA3LjEwNTM2IDE1LjUxOTYgNy4yOTI4OSAxNS43MDcxQzcuNDgwNDMgMTUuODk0NiA3LjczNDc4IDE2IDggMTZDOC4yNjUyMiAxNiA4LjUxOTU3IDE1Ljg5NDYgOC43MDcxMSAxNS43MDcxQzguODk0NjQgMTUuNTE5NiA5IDE1LjI2NTIgOSAxNVY5SDE1QzE1LjI2NTIgOSAxNS41MTk2IDguODk0NjQgMTUuNzA3MSA4LjcwNzExQzE1Ljg5NDYgOC41MTk1NyAxNiA4LjI2NTIyIDE2IDhDMTYgNy43MzQ3OCAxNS44OTQ2IDcuNDgwNDMgMTUuNzA3MSA3LjI5Mjg5QzE1LjUxOTYgNy4xMDUzNiAxNS4yNjUyIDcgMTUgN1oiIGZpbGw9IiM3MDcwNzAiLz4KPC9zdmc+Cg=="), #fff
    &-zoom_out
      background no-repeat center url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMiIgdmlld0JveD0iMCAwIDE2IDIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xNSAwSDhWMUM4IDAuNzM0Nzg0IDguODk0NjQgMC40ODA0MyA4LjcwNzExIDAuMjkyODkzQzguNTE5NTcgMC4xMDUzNTcgOC4yNjUyMiAwIDggMEM3LjczNDc4IDAgNy40ODA0MyAwLjEwNTM1NyA3LjI5Mjg5IDAuMjkyODkzQzcuMTA1MzYgMC40ODA0MyAxMiAwLjczNDc4NCAxMiAxVjBIMUMwLjczNDc4NCAwIDAuNDgwNDMgMC4xMDUzNTcgMC4yOTI4OTMgMC4yOTI4OTNDMC4xMDUzNTcgMC40ODA0MyAwIDAuNzM0Nzg0IDAgMUMwIDEuMjY1MjIgMC4xMDUzNTcgMS41MTk1NyAwLjI5Mjg5MyAxLjcwNzExQzAuNDgwNDMgMS44OTQ2NCAwLjczNDc4NCAyIDEgMkgxMlYxQzEyIDEuMjY1MjIgNy4xMDUzNiAxLjUxOTU3IDcuMjkyODkgMS43MDcxMUM3LjQ4MDQzIDEuODk0NjQgNy43MzQ3OCAyIDggMkM4LjI2NTIyIDIgOC41MTk1NyAxLjg5NDY0IDguNzA3MTEgMS43MDcxMUM4Ljg5NDY0IDEuNTE5NTcgOCAxLjI2NTIyIDggMVYySDE1QzE1LjI2NTIgMiAxNS41MTk2IDEuODk0NjQgMTUuNzA3MSAxLjcwNzExQzE1Ljg5NDYgMS41MTk1NyAxNiAxLjI2NTIyIDE2IDFDMTYgMC43MzQ3ODQgMTUuODk0NiAwLjQ4MDQzIDE1LjcwNzEgMC4yOTI4OTNDMTUuNTE5NiAwLjEwNTM1NyAxNS4yNjUyIDAgMTUgMFoiIGZpbGw9IiM3MDcwNzAiLz4KPC9zdmc+Cg=="), #fff
  &-control_separator
    width 30px
    height 1px
    margin 0 5px
    background-color #e6e6e6

// overriding of the default Google Map style
.gm-style
  &-iw-t
    &::after
      content none !important
    .gm-ui-hover-effect
      display none !important
  &-iw-c
    padding 16px 13px !important
    border-radius 2px !important
    box-shadow 0 0 11px rgba(0, 0, 0, 0.05) !important
    transform translate(-50%,-90%) !important
    @media screen and (max-width 375px)
      max-width calc(100vw - 58px) !important
    @media screen and (min-width 376px) and (max-width 526px)
      max-width calc(100vw - 98px) !important
  &-iw-d
    font-weight 600 !important
    font-size 15px !important
    line-height 20px !important
    letter-spacing 0.2px !important

  .gm-style-cc
  .gmnoprint
    display none !important

  div > div > a
    display none !important

mat-tooltip-component .kkm-table-tooltip-above.parent-receipt-tooltip-above
  max-width 300px !important
  transform-origin initial
  text-align center
  margin 0 0 -5px 25px !important

.currency-catalog-filter
  &__search-field
    .mat-form-field-flex
      height 4.8rem !important

.currency-catalog-table
  .mat-header-cell,
  .mat-cell
    font-size var(--fs-h4)
    line-height var(--lh-h4)
  tr.mat-row
    height 5.6rem
  &__row_editable
    .mat-cell
      padding-top 0.4rem !important
      padding-bottom 0.4rem !important
  &__form-field
    .mat-form-field-flex
      height 4.8rem !important

.color-filtered
  filter: hue-rotate(65deg)
